import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { reduxForm } from 'redux-form';
import { Trans, withTranslation } from 'react-i18next';

import FileInput from '@common/components/form/inputs/file';
import * as alert from '../../../../common/services/alert';
import { Button } from '../../../../common/components/button';
import Modal from '../../../../common/components/modal';
import Confirm from '../../../../common/components/confirm-button';
import { Row, Group, Label, SelectInput } from '../../../../common/components/form';
import uploadSchedule from '../../actions/upload-schedule';
import validate from '../validators/schedule';

const accepts = 'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

class UploadForm extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    network: PropTypes.object.isRequired,
    functions: PropTypes.array.isRequired,
    schedules: PropTypes.array.isRequired,
    show: PropTypes.bool,
    onClose: PropTypes.func,
    formValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
  };

  static defaultProps = {
    formValues: {},
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(values, dispatch) {
    const { reset, t, onClose } = this.props;

    try {
      await dispatch(uploadSchedule(values));

      alert.success(
        <Trans i18nKey="schedules:form_upload_success" values={{ week: moment(values.week).isoWeek() }} components={[<b>a</b>]} />,
      );

      setTimeout(() => (this.modal ? this.modal.handleClose() : false), 1500);

      reset();
    } catch (response) {
      alert.forStatus(response.status_code, {
        warning: t('schedules:form_upload_warning'),
        error: t('schedules:form_upload_error'),
      });
    }

    onClose?.();
  }

  createUploadForOptions() {
    const { network, functions, t } = this.props;

    return [
      { value: 'network', label: t('schedules:form_upload_community', { networkName: network.name }) },
      ...functions.map((organisationFunction) => ({
        value: organisationFunction.id,
        label: organisationFunction.name,
      })),
    ];
  }

  createWeekOptions() {
    const { t } = this.props;

    return R.range(0, 5).map((week) => {
      const value = moment().add(week, 'weeks').startOf('isoWeek');

      return { value: value.format('YYYY-MM-DD'), label: t('schedules:week_number', { weekNumber: value.isoWeek() }) };
    });
  }

  render() {
    const {
      children,
      schedules,
      invalid,
      submitting,
      handleSubmit,
      formValues,
      onClose,
      show,
      t,
    } = this.props;

    const formValuesWeekNumber = moment(formValues.week).isoWeek();
    const matchingSchedule = R.find(R.allPass([
      R.propEq('week', formValuesWeekNumber),
      R.propEq('function_id', formValues.function_id !== 'network' ? formValues.function_id : null),
    ]), schedules);

    return (
      <Modal
        list
        ref={(ref) => (this.modal = ref)}
        show={show}
        title={t('schedules:form_upload_modal_title')}
        wrapper={Modal.FormWrapper}
        wrapperProps={{
          onSubmit: handleSubmit(this.handleSubmit),
        }}
        onClose={onClose}
        content={(
          <div>
            <Row>
              <Group>
                <Label forInput="function_id" text={t('schedules:form_upload_modal_team_title')} />
                <SelectInput
                  name="function_id"
                  options={this.createUploadForOptions()}
                  placeholder={t('schedules:form_upload_modal_team_placeholder')}
                  clearable={false}
                />
              </Group>
              <Group>
                <Label forInput="week" text={t('schedules:form_upload_modal_week_title')} />
                <SelectInput
                  name="week"
                  options={this.createWeekOptions()}
                  placeholder={t('schedules:form_upload_modal_week_placeholder')}
                  clearable={false}
                />
              </Group>
            </Row>

            <Group>
              <Label forInput="schedule" text={t('schedules:form_upload_modal_schedule_title')} />
              <FileInput name="schedule" accept={accepts} processFile={false} />
            </Group>
          </div>
        )}
        footer={matchingSchedule ? (
          <Confirm
            title={t('schedules:form_upload_confirm_overwrite')}
            onConfirm={handleSubmit(this.handleSubmit)}
          >
            <Button
              type="primary"
              isLoading={submitting}
              disabled={invalid}
            >
              <Trans i18nKey="schedules:form_upload_button" />
            </Button>
          </Confirm>
        ) : (
          <Button
            type="primary"
            isLoading={submitting}
            disabled={invalid}
            buttonType="submit"
          >
            <Trans i18nKey="schedules:form_upload_button" />
          </Button>
        )}
      >
        {children}
      </Modal>
    );
  }
}

export default withTranslation(undefined, { withRef: true })(reduxForm({
  form: 'upload-schedule',
  initialValues: {
    function_id: 'network',
    week: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    schedule: null,
  },
  validate,
})(UploadForm));
