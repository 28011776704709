import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import ConfirmButton from '../../../../common/components/confirm-button';
import { Button } from '../../../../common/components/button';
import Permission from '../../../../common/components/permission';
import Icon from '../../../../common/components/icon';
import { EPermissions } from '../../../../common/definitions';

const ScheduleItem = ({
  item, onAdd, onDelete, onOpen, t,
}) => {
  if (!item || !item.path) {
    return (
      <Permission name={[EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_SCHEDULES_CREATE, EPermissions.NETWORK_SCHEDULES_CREATE]}>
        <Button
          type="inverted-primary"
          className="Schedule--add"
          size="small"
          onClick={() => onAdd(item)}
        >
          <Trans i18nKey="schedules:add_button" />
        </Button>
      </Permission>
    );
  }

  return (
    <div className="Schedule">
      <a href={item.path} target="_blank" onClick={onOpen} rel="noreferrer">
        <Button type="inverted-primary" size="small">
          <Trans i18nKey="schedules:view_button" />
        </Button>
      </a>

      <div className="Schedule__delete">
        <Permission name={[EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_SCHEDULES_REMOVE, EPermissions.NETWORK_SCHEDULES_REMOVE]}>
          <ConfirmButton
            danger
            title={t('schedules:delete_button')}
            description={t('schedules:deletion_description')}
            onConfirm={() => onDelete(item.id)}
            confirmText={t('schedules:delete_confirmation_button')}
          >
            <a><Icon type="delete" /></a>
          </ConfirmButton>
        </Permission>
      </div>
    </div>
  );
};

export default withTranslation()(ScheduleItem);
